@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-small-radial-gradient {
  --tw-gradient-stops: theme('colors.purple.800'), theme('colors.black'),
    theme('colors.black');
  background-image: radial-gradient(var(--tw-gradient-stops));
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.grid-cols-auto-fit {
  grid-template-columns: repeat(auto-fit, minmax(125px, 2fr));
}

.grid-cols-auto-fit::after {
  content: '';
  grid-column-end: -1;
}

:root {
  --bg: #151c2c;
  --bgSoft: #182237;
  --text: white;
  --textSoft: #b7bac1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: black;
}

body {
  color: var(--text);
}

a {
  color: inherit;
  text-decoration: none;
}

.menu-item-transition {
  transition-property: background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

/* Animated Progress Circle  */
.progress-ring__circle {
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

@keyframes progress {
  to {
    stroke-dashoffset: 0;
  }
}

/* Reusable Utility Classed for Tailwind */

@layer components {
  .prime-border-selected {
    @apply rounded-xl border border-[#A68EE9] ring-[#A68EE9];
  }

  .prime-border-clickable {
    @apply border border-gray-800 border-slate-100;
  }

  .prime-border {
    @apply rounded-xl border border-[#141414] ring-[#231D29];
  }

  .prime-border-thin {
    @apply border border-gray-800;
  }
}
